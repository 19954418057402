<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" class="d-flex align-center">
        <v-img class="mt-8 mb-7" src="~@/assets/site_logo.png" height="150" contain></v-img>
      </v-col>
    </v-row>

    <v-row justify="center" >
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Search for proteins or diseases
          </v-card-title>
          <v-card-text>
            <KeywordSearch :list-on-top="true" :caption="'Try:  Alzheimer\'s Disease     C0030567      J45.9     MAOA      P01375     hsa:1812'"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" >
      <v-col cols="12">
        <v-card>
          <v-card-title>DATTs: Disease-Associated Therapeutic Targets</v-card-title>
          <v-img src="~@/assets/description.png" height="180" contain></v-img>
          <v-card-text class="px-8">
            DATTs is a database of therapeutic targets and their inhibitory and activatory actions on diseases.
            The information is extracted from public documents such as scientific papers and medical books.
            The available information include disease-therapeutic targets associations,
            inhibitory or activatory actions of target proteins on diseases, and desired regulations for medical treatment.
            Further information such as links to molecular functions of target proteins and classifications of diseases is also accessible.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" >
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Database statistics
          </v-card-title>
          <v-card-text>
            <v-card flat>
              <v-card-title>
                Data statistics of diseases and all therapeutic targets
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="statHeaders"
                  :items="statDataTotal"
                  item-key="id"
                  class="elevation-1"
                  hide-default-footer
                  disable-sort
                  no-data-text="Loading..."
                >
                </v-data-table>
              </v-card-text>
            </v-card>
            <v-card flat>
              <v-card-title>
                Data statistics of diseases and inhibitory therapeutic targets
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="statHeaders"
                  :items="statDataInh"
                  item-key="id"
                  class="elevation-1"
                  hide-default-footer
                  no-data-text="Loading..."
                >
                </v-data-table>
              </v-card-text>
            </v-card>
            <v-card flat>
              <v-card-title>
                Data statistics of diseases and activatory therapeutic targets
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="statHeaders"
                  :items="statDataAct"
                  item-key="id"
                  class="elevation-1"
                  hide-default-footer
                  no-data-text="Loading..."
                >
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import KeywordSearch from '@/components/KeywordSearch'
export default {
  name: 'HomeSearch',
  components: {
    KeywordSearch
  },
  data: () => ({
    statHeaders: [
      { text: '# of diseases', value: 'diseases' },
      { text: '# of all targets', value: 'targets' },
      { text: '# of disease-target associations', value: 'relationships' }
    ],
    statDataTotal: [],
    statDataInh: [],
    statDataAct: []
  }),
  mounted() {
    const self = this
    this.api.getStatistics(function(data) {
      self.statDataTotal = [data.statDataTotal]
      self.statDataInh = [data.statDataInh]
      self.statDataAct = [data.statDataAct]
    }, function(error) {
      console.log(error)
    })
  }
}
</script>

<style scoped>
::v-deep .v-data-table__wrapper > table > tbody > tr:hover {
  background: inherit !important;
}
</style>
