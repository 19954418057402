<template>
  <v-container>
    <v-row justify="center" >
      <v-col cols="12">
        <v-card>
          <v-card-title>Downloading data</v-card-title>
          <v-card-text>
            The information on therapeutic targets for various diseases and their detailed actions on diseases is downloadable.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" >
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Information about the download files
          </v-card-title>
          <v-card-text>
            <div>
              <div v-for="filename in filenames" :key="filename">
                <a @click="downloadFile(filename)">{{filename}}</a>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'DownloadData',

  data() {
    return {
      filenames: []
    }
  },
  mounted() {
    const self = this
    this.api.getFileNamesToDownload(function(data) {
      self.filenames = data.filenames
    }, function(error) {
      console.log(error)
    })
  },
  methods: {
    downloadFile(filename) {
      const self = this
      this.api.downloadFile(filename, function(response) {
        self.api.downloadFileHelper(response, filename)
      }, function(error) {
        console.log(error)
      })
    }
  }
}
</script>
