<template>
  <v-container>
    <v-row justify="center" >
      <v-col cols="12">
        <v-card>
          <v-card-title>About</v-card-title>
          <v-card-text>
            DATTs is a collection of dispersed public information on therapeutic targets for various diseases and their detailed actions on diseases. To our knowledge, there is no such resource with format in machine-readable. The knowledge of therapeutic targets for various diseases and their detailed actions on diseases should have many applications in medical and pharmaceutical research. This database is expected to enhance drug discovery.           </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" >
      <v-col cols="12">
        <v-card>
          <v-card-title> References </v-card-title>
          <v-card-text>
            <v-row v-for="(ref, i) in references" :key="i">
              <v-col cols="1"> {{ ref.index }}. </v-col>
              <v-col cols="7">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ ref.author }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <a :href=ref.url>{{ ref.title }}</a>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ ref.date }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HomeSearch',

  data: () => ({
    references: [
      {
        index: 1,
        author: 'Sawada, R., Iwata, M., Tabei, Y., Yamato, H., and Yamanishi, Y.',
        title: 'Predicting inhibitory and activatory drug targets by chemically and genetically perturbed transcriptome signatures',
        url: 'https://pubmed.ncbi.nlm.nih.gov/29317676/',
        date: 'Scientific Reports, 8:156, 2018.'
      },
      {
        index: 2,
        author: 'Sawada, R., Iwata, H., Mizutani, S., and Yamanishi, Y.',
        title: 'Target-based drug repositioning using large-scale chemical-protein interactome data',
        url: 'https://pubmed.ncbi.nlm.nih.gov/26580494/',
        date: 'Journal of Chemical Information and Modeling, 55(12), 2717–2730, 2015.'
      },
      {
        index: 3,
        author: 'Yamanishi, Y., Kotera, M., Moriya, Y., Sawada, R., Kanehisa, M., and Goto, S. ,',
        title: 'DINIES: drug-target interaction network inference engine based on supervised analysis',
        url: 'https://pubmed.ncbi.nlm.nih.gov/24838565/',
        date: 'Nucleic Acids Research, 42, W39-W45, 2014.'
      }
    ]
  })
}
</script>
