const keywordSearch = {
  namespaced: true,
  state: {
    searchWords: []
  },
  mutations: {
    addSearchWord(state, searchWord) {
      state.searchWords = state.searchWords.filter(word => word !== searchWord)
      state.searchWords.push(searchWord)
      state.searchWords = state.searchWords.slice(-3)
    }
  },
  getters: {
    searchWords(state) {
      return state.searchWords
    }
  }
}

export default keywordSearch
