<template>
  <div class="Help">
    <div v-html="htmlContent"></div>
  </div>
</template>

<script>
export default {
  name: 'Help',
  data() {
    return {
      htmlContent: ''
    }
  },
  mounted: function() {
    this.loadHtml()
  },
  methods: {
    loadHtml() {
      fetch('./html/help.htm').then((res) => {
        res.text().then((html) => {
          this.htmlContent = html
        })
      })
    }
  }
}
</script>
