<template>
  <v-container>
    <v-row justify="center" >
      <v-col cols="12">
        <v-card>
          <v-sheet class="pa-4 " color="#00085c">
            <v-text-field
              v-model="inputedSearch"
              label="Search for a disease"
              dark
              flat
              solo-inverted
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
              append-icon="mdi-magnify"
            ></v-text-field>
            <v-checkbox
              v-model="caseSensitive"
              dark
              hide-details
              label="Case sensitive search"
              class="mb-2"
            ></v-checkbox>
            <v-btn
              :loading="loading"
              :disabled="loading"
              @click="expandAllNodes"
              class="pa-2 ma-2"
            >
              <div v-if="this.allOpened">
                Collapse all nodes
              </div>
              <div v-else>
                Expand all nodes
              </div>
            </v-btn>
          </v-sheet>
          <v-card-text>
            <v-treeview
              ref="tree"
              v-model="tree"
              dense
              open-on-click
              hoverable
              :search="search"
              :filter="filter"
              :open.sync="open"
              :items="diseaseTree"
            >
              <template v-slot:label="{ item, leaf }">
                <div>
                  <router-link v-if='leaf && item.disease' :to="$router.resolve({ name: 'DiseaseDetail', params: {id: item.disease} }).href">{{ item.name }}</router-link>
                  <div v-else>{{ item.name }}</div>
                </div>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
export default {
  apollo: {
    diseaseTree: {
      query: gql`
        query {
          diseaseTree
        }
      `,
      update: (data) => JSON.parse(data.diseaseTree)
    }
  },
  data() {
    return {
      diseaseTree: [],
      topLevelKeys: [],
      open: [],
      allOpened: false,
      tree: [],
      inputedSearch: null,
      search: null,
      caseSensitive: false,
      isVisible: true,
      loader: null,
      loading: false
    }
  },
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => {
          return item[textKey].indexOf(search) > -1
        }
        : undefined
    }
  },
  methods: {
    onClickDetails(id) {
      const route = this.$router.resolve({
        name: 'DiseaseDetail',
        params: { id: id }
      })
      return route.href
    },
    toggleIsVisible() {
      this.isVisible = !this.isVisible
    },
    expandAllNodes() {
      this.allOpened = !this.allOpened
      this.allOpened
        ? this.$refs.tree.updateAll(this.allOpened)
        : this.open = this.defaultOpen
    }
  },
  watch: {
    inputedSearch: function(newVal, oldVal) {
      if (newVal == null || newVal.length === 0) {
        this.search = ''
      } else if (newVal.length >= 2) {
        this.search = newVal
      }
    },
    diseaseTree: function(newVal, oldVal) {
      this.defaultOpen = this.diseaseTree.map(val => val.id)
      this.open = this.defaultOpen
    }
  }
}
</script>
