import axios from 'axios'

const API_URI = process.env.VUE_APP_API_BASE_URI + '/api'

const getFileNamesToDownload = function(callback, errorCallback) {
  axios.get(
    `${API_URI}/filenames_to_download/`
  ).then(function(response) {
    callback(response.data)
  }).catch(function(e) {
    errorCallback(e)
  })
}

const downloadFile = function(filename, callback, errorCallback) {
  axios.get(
    `${API_URI}/download/${filename}/`
  ).then(function(response) {
    callback(response)
  }).catch(function(e) {
    errorCallback(e)
  })
}

const downloadFileHelper = function(response, filename, json = false) {
  let content = response.data
  if (json) {
    content = JSON.stringify(content, null, 2)
  }
  const url = new Blob([content], {
    type: response.headers['content-type']
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(url)
  link.download = filename
  link.click()
  window.URL.revokeObjectURL(url)
  link.remove()
}

const getStatistics = function(callback, errorCallback) {
  axios.get(
    `${API_URI}/calculate_statistical_number/`
  ).then(function(response) {
    callback(response.data)
  }).catch(function(e) {
    errorCallback(e)
  })
}

export default {
  getFileNamesToDownload,
  downloadFile,
  downloadFileHelper,
  getStatistics
}
