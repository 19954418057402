import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import api from '@/api'
import store from '@/store'
import { createProvider } from './vue-apollo'

Vue.config.productionTip = false
Vue.prototype.api = api
Vue.api = api

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
