<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>{{disease.nameEn}}</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col :cols="2">
                Disease ID:
              </v-col>
              <v-col>
                {{disease.diseaseId}}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col :cols="2">
                Name:
              </v-col>
              <v-col>
                {{disease.nameEn}}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col :cols="2">
                UMLS:
              </v-col>
              <v-col>
                {{disease.umls}}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col :cols="2">
                ICD10:
              </v-col>
              <v-col>
                {{disease.icd10}}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Proteins
          </v-card-title>
          <v-card-text>

            <v-data-table
              :headers="headers"
              :items="relationships"
              item-key="proteinId"
              class="elevation-1"
              :footer-props="{
                'items-per-page-options': [50, 100, -1]
              }"
            >
              <template #item.proteinId="{ item }">
                <router-link :to="$router.resolve({ name: 'ProteinDetail', params: {id: item.id} }).href">{{ item.proteinId }}</router-link>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'

export default {
  apollo: {
    disease: {
      query: gql`
        query ($id: Int) {
          diseaseDetail(id: $id) {
            diseaseId
            nameEn
            umls
            icd10
            relationships {
              relType
              source
              protein {
                id
                proteinId
                geneSymbol
                definition
                keggGeneId
                uniprotId
              }
            }
          }
        }
      `,
      update: (data) => data.diseaseDetail,
      variables() {
        return {
          id: this.id
        }
      }
    }
  },
  name: 'DiseaseDetail',
  components: {
  },
  data() {
    return {
      id: this.$route.params.id,
      disease: {},
      relationships: [],
      headers: [
        {
          text: 'Protein ID',
          align: 'start',
          sortable: true,
          value: 'proteinId'
        },
        { text: 'Type', value: 'relType' },
        { text: 'GeneSymbol', value: 'geneSymbol' },
        { text: 'Definition', value: 'definition' },
        { text: 'KEGG Gene ID', value: 'keggGeneId' },
        { text: 'Uniprot ID', value: 'uniprotId' },
        { text: 'References', value: 'source' }
      ]
    }
  },
  watch: {
    disease: function(newVal, oldVal) {
      this.relationships = newVal.relationships.map(rel => {
        return {
          relType: rel.relType,
          source: rel.source,
          ...rel.protein
        }
      })
    }
  }
}
</script>
<style scoped>
.theme--light.v-card > .v-card__text, .theme--light.v-card > .v-card__subtitle {
  color: black
}
</style>
