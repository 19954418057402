<template>
  <v-container>
    <div class="keyword-search">
      <div ref="ksbox">
        <v-combobox
          :search-input.sync="inputedKeyword"
          :items="history"
          :label="label"
          :placeholder="label"
          :menu-props="{ top: listOnTop }"
          append-icon=""
          outlined
          dense
          clearable
          solo
          @update:search-input="inputKeyword"
        />
        <div class="keyword-caption"> {{ caption }}</div>
      </div>
      <div id="search-result-dialog" class="dialog">
        <v-card>
          <v-card-title>
            Search results :
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon @click="dialogToggle" class="dialog__close">
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-data-table
                  :headers="diseaseHeaders"
                  :items="diseaseList"
                  item-key="id"
                  class="elevation-1 dialog__table"
                >
                  <template #item.diseaseId="{ item }">
                    <router-link  @click.native="onClickDetails" :to="$router.resolve({ name: 'DiseaseDetail', params: {id: item.id} }).href">{{ item.diseaseId }}</router-link>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="6">
                <v-data-table
                  :headers="proteinHeaders"
                  :items="proteinList"
                  item-key="id"
                  class="elevation-1 dialog__table"
                >
                  <template #item.proteinId="{ item }">
                    <router-link @click.native="onClickDetails" :to="$router.resolve({ name: 'ProteinDetail', params: {id: item.id} }).href">{{ item.proteinId }}</router-link>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { mapGetters } from 'vuex'

export default {
  name: 'HomeSearch',
  props: {
    listOnTop: {
      type: Boolean,
      default: () => false
    },
    label: {
      type: String,
      default: () => 'type 3 or more characters...',
      required: false
    },
    caption: {
      type: String,
      default: () => '',
      required: false
    }
  },
  apollo: {
    result: {
      query: gql`
        query ($keyword: String) {
          diseaseList(keyword: $keyword) {
            id
            diseaseId
            nameEn
            umls
            icd10
          }
          proteinList(keyword: $keyword) {
            id
            proteinId
            geneSymbol
            definition
            keggGeneId
            uniprotId
          }
        }
      `,
      update: (data) => data,
      variables() {
        return {
          keyword: this.keyword
        }
      }
    }
  },
  data() {
    return {
      inputedKeyword: '',
      keyword: '',
      result: null,
      show: false,
      diseaseList: [],
      diseaseHeaders: [
        {
          text: 'Disease ID',
          align: 'start',
          sortable: true,
          value: 'diseaseId'
        },
        { text: 'Name', value: 'nameEn' }
      ],
      proteinList: [],
      proteinHeaders: [
        {
          text: 'Protein ID',
          align: 'start',
          sortable: true,
          value: 'proteinId'
        },
        { text: 'GeneSymbol', value: 'geneSymbol' }
      ]

    }
  },
  watch: {
    show: {
      handler() {
        const vdrag = document.getElementById('search-result-dialog')
        if (this.show) {
          vdrag.style.display = 'block'
          vdrag.focus()
        } else {
          vdrag.style.display = 'none'
        }
      }
    },
    result: {
      handler(newVal, oldVal) {
        this.proteinList = newVal.proteinList ?? []
        this.diseaseList = newVal.diseaseList ?? []
      }
    }
  },
  computed: {
    ...mapGetters('keywordSearch', {
      history: 'searchWords'
    })
  },
  created() {
    const vdrag = document.getElementById('search-result-dialog')
    if (vdrag) {
      vdrag.remove()
    }
  },
  mounted() {
    const vdrag = document.getElementById('search-result-dialog')
    this.deleteDescendantsClass(vdrag, 'theme--dark')

    const ksBox = this.$refs.ksbox
    const rect = ksBox.getBoundingClientRect()
    const dialogTop = rect.bottom + 30 + 'px'
    vdrag.style.top = dialogTop
    if (rect.left / window.innerWidth > 0.75) {
      vdrag.style.left = 25 + '%'
    } else {
      vdrag.style.left = rect.left + 'px'
    }
    document.getElementById('app').appendChild(vdrag)
  },
  methods: {
    inputKeyword(newVal) {
      if (this.validKeyword(newVal)) {
        this.keyword = newVal
        this.show = true
      } else {
        this.show = false
      }
    },
    onClickDetails() {
      if (this.keyword) {
       this.$store.commit('keywordSearch/addSearchWord', this.keyword)
      }
      this.show = false
    },
    dialogToggle() {
      this.show = !this.show
    },
    deleteDescendantsClass(elm, className) {
      elm.classList.remove(className)
      if (elm.children.length > 0) {
        Array.from(elm.children).map((e) =>
          this.deleteDescendantsClass(e, className)
        )
      }
    },
    validKeyword(keyword) {
      return !(keyword == null || keyword === undefined || keyword === '' || keyword.length < 3)
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  position: absolute;
  display: none;
  width: 70%;
  z-index: 2;
  border-radius: 2px;
  border-color: #000000;
  background-color: #ffffff;
  &__close {
    cursor: pointer;
  }
}

::v-deep .v-messages__wrapper {
  color: white;
}

.keyword-search .keyword-caption {
  white-space: pre-wrap;
  font-size: 15px;
}
</style>
