<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>{{protein.proteinId}}</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col :cols="2">
                Gene Symbol:
              </v-col>
              <v-col>
                {{protein.geneSymbol}}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col :cols="2">
                Definition:
              </v-col>
              <v-col>
                {{protein.definition}}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col :cols="2">
                kegg Gene Id:
              </v-col>
              <v-col>
                <a target="_blank" rel="noreferrer" :href="'https://www.genome.jp/entry/' + protein.keggGeneId">{{protein.keggGeneId}}</a>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col :cols="2">
                Uniprot ID:
              </v-col>
              <v-col>
                {{protein.uniprotId}}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Diseases
          </v-card-title>
          <v-card-text>

            <v-data-table
              :headers="headers"
              :items="relationships"
              item-key="diseaseId"
              class="elevation-1"
              :footer-props="{
                'items-per-page-options': [50, 100, -1]
              }"
            >
              <template #item.diseaseId="{ item }">
                <router-link :to="$router.resolve({ name: 'DiseaseDetail', params: {id: item.id} }).href">{{ item.diseaseId }}</router-link>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'

export default {
  apollo: {
    protein: {
      query: gql`
        query ($id: Int) {
         proteinDetail(id: $id) {
         proteinId
         geneSymbol
         definition
         keggGeneId
         uniprotId
         relationships {
           relType
           source
           disease {
             id
             diseaseId
             nameEn
             umls
             icd10
           }
          }
         }
        }
      `,
      update: (data) => data.proteinDetail,
      variables() {
        return {
          id: this.id
        }
      }
    }
  },
  name: 'ProteinDetail',
  components: {
  },
  data() {
    return {
      id: this.$route.params.id,
      protein: {},
      relationships: [],
      headers: [
        {
          text: 'Disease ID',
          align: 'start',
          sortable: true,
          value: 'diseaseId'
        },
        { text: 'Type', value: 'relType' },
        { text: 'Name', value: 'nameEn' },
        { text: 'ICD10', value: 'icd10' },
        { text: 'UMLS', value: 'umls' },
        { text: 'References', value: 'source' }
      ]
    }
  },
  watch: {
    protein: function(newVal, oldVal) {
      this.relationships = newVal.relationships.map((rel) => {
        return {
          relType: rel.relType,
          source: rel.source,
          ...rel.disease
        }
      })
    }
  }
}
</script>
<style scoped>
.theme--light.v-card > .v-card__text, .theme--light.v-card > .v-card__subtitle {
  color: black
}
</style>
