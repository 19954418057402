import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import DiseaseList from '@/views/DiseaseList'
import ProteinList from '@/views/ProteinList'
import DownloadData from '@/views/DownloadData'
import About from '@/views/About'
import Help from '@/views/Help'
import DiseaseDetail from '@/views/DiseaseDetail'
import ProteinDetail from '@/views/ProteinDetail'

Vue.use(Router)

const hideDownload = process.env.VUE_APP_HIDE_DOWNLOAD || 'true'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/diseases',
    name: 'DiseaseList',
    component: DiseaseList,
    meta: {
      title: 'DiseaseList'
    }
  },
  {
    path: '/proteins',
    name: 'ProteinList',
    component: ProteinList,
    meta: {
      title: 'ProteinList'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'About'
    }
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
    meta: {
      title: 'Help'
    }
  },
  {
    path: '/diseases/:id',
    name: 'DiseaseDetail',
    component: DiseaseDetail,
    meta: {
      title: 'DiseaseDetail'
    }
  },
  {
    path: '/proteins/:id',
    name: 'ProteinDetail',
    component: ProteinDetail,
    meta: {
      title: 'ProteinDetail'
    }
  }
]
if (hideDownload !== 'true') {
  routes.push(
    {
      path: '/download',
      name: 'DownloadData',
      component: DownloadData,
      meta: {
        title: 'DownloadData'
      }
    }

  )
}

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach((toRoute, fromRoute, next) => {
  if (toRoute.meta && toRoute.meta.title) {
    window.document.title = 'DATTs - ' + toRoute.meta.title
  } else {
    window.document.title = 'DATTs'
  }
  next()
})

export default router
