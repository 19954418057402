<template>
  <v-app>
    <AppHeader/>
    <v-main v-bind:class="{ background: showBanner }">
        <v-container>
          <router-view v-if="show"></router-view>
        </v-container>
    </v-main>
    <v-footer>
      <cookie-law
        :buttonText="cookieButton"
        theme="royal">
        <div slot="message">
          <span v-html="cookieLaw" /> <a :href="cookieLink" target="_blank">{{ cookieMore }}</a>
        </div>
      </cookie-law>
    </v-footer>
  </v-app>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

import AppHeader from './components/AppHeader.vue'
export default {
  name: 'App',

  components: {
    AppHeader,
    CookieLaw
  },
  data() {
    return {
      show: true,
      cookieLink: 'https://gdpr.eu/cookies/',
      cookieLaw: 'This website uses essential cookies for its core functionality, but neither stores personal data nor uses any kind of trackers. <br/> By using this website, you are consenting to the use of these cookies.',
      cookieMore: 'Learn more...',
      cookieButton: 'Got it!'
    }
  },
  computed: {
    showBanner() {
      return this.$route.name === 'Home'
    }
  },
  watch: {
    // 検索窓から検索して、
    // 例えばdiseaseDetailから別IDのdiseaseDetailに遷移したとき
    // きちんと再描画されるための処理
    $route(to, from) {
      if (to.name === from.name && to.fullPath !== from.fullPath) {
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    }
  }
}
</script>

<style scoped>
.background {
  background:url(~@/assets/background.png) no-repeat;
  background-size : 100% auto;
  background-position: 0px 112px;
}
</style>
