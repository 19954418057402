<template>
  <header>
    <v-app-bar app color="#000a6e">
      <v-toolbar-title
        @click="$router.push({ name: 'Home' })"
        style="cursor: pointer"
        class="mt-5"
      >
        <div class="middle mb-3">
          <img src="~@/assets/logo.png" height="45">
          <h1 class="site-title pl-3">DATTs</h1>
        </div>
      </v-toolbar-title>
      <v-app-bar-nav-icon
        @click="drawer = true"
        class="d-flex d-sm-none"
      ></v-app-bar-nav-icon>
      <template v-slot:extension>
        <v-tabs v-model="tab" align-with-title class="d-none d-sm-flex">
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab
            v-for="(menuItem, index) in menuItems"
            :key="index"
            :to="onClickMenu(menuItem.name)"
            style="color:white"
          >
            {{ menuItem.text }}
          </v-tab>
        </v-tabs>
        <v-spacer></v-spacer>
        <div v-if="isNotHome" class="d-none d-sm-flex mb-4">
          <KeywordSearch :label="'Search...'" />
        </div>
      </template>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary right>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item
            v-for="(menuItem, index) in menuItems"
            :key="index"
            :to="onClickMenu(menuItem.name)"
          >
            <v-list-item-title @click="tab = index">
              {{ menuItem.name }}</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </header>
</template>

<script>
import KeywordSearch from '@/components/KeywordSearch'

export default {
  name: 'AppHeader',
  components: {
    KeywordSearch
  },
  data() {
    return {
      drawer: false,
      tab: null,
      hideDownload: process.env.VUE_APP_HIDE_DOWNLOAD || 'true',
      menuItems: []
    }
  },
  computed: {
    isNotHome() {
      return this.$route.name !== 'Home'
    }
  },
  mounted() {
    this.menuItems = this.hideDownload === 'true'
      ? [
        { text: 'Home', name: 'Home' },
        { text: 'Disease list', name: 'DiseaseList' },
        { text: 'Protein list', name: 'ProteinList' },
        { text: 'About', name: 'About' },
        { text: 'Help', name: 'Help' }
      ]
      : [
        { text: 'Home', name: 'Home' },
        { text: 'Disease list', name: 'DiseaseList' },
        { text: 'Protein list', name: 'ProteinList' },
        { text: 'Download', name: 'DownloadData' },
        { text: 'About', name: 'About' },
        { text: 'Help', name: 'Help' }
      ]
  },
  methods: {
    onClickMenu(name) {
      const route = this.$router.resolve({
        name: name
      })
      return route.href
    }
  }
}
</script>

<style scoped>
.middle {
  white-space: nowrap;
}
.middle > * {
  vertical-align: middle;
}
h1 {
  display: inline-block;
  text-align: right;
  font: bold 20px Sans-Serif;
  color: white;
  font-size: 30px;
}

</style>
