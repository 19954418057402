import Vue from 'vue'
import Vuex from 'vuex'
import keywordSearch from './modules/KeywordSearch'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    keywordSearch: keywordSearch
  }
})

export default store
